<template>
  <div
    :class="{'row-item': true, 'completed': !row.requiresReview}"
  >
    <h6>
      <v-icon v-if="!row.requiresReview">
        mdi-check
      </v-icon>
      Row #{{ row.originalRowNumber }}
      <span
        v-if="!row.requiresReview"
        class="undo-link ml-2"
        @click="undo"
      >
        undo
      </span>
    </h6>

    <div class="row-table">
      <v-simple-table>
        <thead>
          <tr class="row-table__header">
            <th
              v-for="(header, index) in rowHeaders"
              :key="index"
              v-text="header"
            />
          </tr>
        </thead>
        <tbody class="row-table__content">
          <tr
            v-for="(rowToReview, index) in rowsWithValues"
            :key="index"
            class="review-row"
          >
            <td
              v-for="(header, cellindex) in rowHeaders"
              :key="cellindex"
            >
              <template v-if="header === 'Address'">
                <template v-if="rowToReview[header].length > 1">
                  <v-radio-group
                    v-model="selectedAddress"
                    class="radio-group-item"
                  >
                    <div
                      v-for="possibleMatch in rowToReview[header].matches"
                      :key="possibleMatch.id"
                      class="position-relative multiple-items d-flex"
                    >
                      <div class="mr-2 text-center">
                        <span
                          class="match-number"
                        >
                          {{ possibleMatch.score }}%
                          <br>
                          match
                        </span>
                      </div>
                      <v-radio
                        :value="possibleMatch.id"
                        name="address"
                        class="mr-2"
                      />
                      <div class="mt-2">
                        {{ formatAddressMatchForDisplay(possibleMatch) }}
                      </div>
                    </div>
                  </v-radio-group>
                </template>
                <template v-else>
                  {{ formatAddressMatchForDisplay(rowToReview[header][0]) }}
                </template>
              </template>
              <template v-else-if="header === 'Matched Identifier'">
                <template v-if="rowToReview['Source'] === 'Database'">
                  <a
                    @click="redirectToContactProfile"
                  >
                    {{ rowToReview[header] }}
                  </a>
                </template>
                <template v-else>
                  {{ rowToReview[header] }}
                </template>
              </template>
              <template v-else>
                <template v-if="header === 'Source'">
                  <strong>{{ rowToReview[header] }}</strong>
                </template>
                <template v-else>
                  {{ rowToReview[header] }}
                </template>
              </template>
            </td>
          </tr>
          <tr class="preview-row">
            <td
              v-for="(header, cellindex) in rowHeaders"
              :key="cellindex"
            >
              <template v-if="header === 'Source'">
                <strong>{{ previewRow[header] }}</strong>
              </template>
              <template v-else>
                {{ previewRow[header] }}
              </template>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <div
      v-if="row.requiresReview"
      class="row-actions"
    >
      <v-row>
        <v-col :cols="3">
          <CustomDropdown
            v-model="contactActionSelection"
            placeholder="Contact Action"
            header="Contact Action"
            :items="getContactActions(rowsWithValues)"
            item-text="text"
            item-value="id"
          />
        </v-col>
        <v-col
          v-if="rowHeaders.includes('Address')"
          :cols="3"
        >
          <CustomDropdown
            v-model="addressActionSelection"
            placeholder="Address Action"
            header="Address Action"
            :items="addressActions"
            item-text="text"
            item-value="id"
          />
        </v-col>
        <v-col :cols="2">
          <v-btn
            class="custom-button custom-button--gray mt-10 px-11 mr-sm-5 mb-4 mb-sm-0"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="process"
          >
            Accept
          </v-btn>
        </v-col>
      </v-row>
      <v-divider class="my-3" />
    </div>
  </div>
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";

export default {
  name: "RowItem",
  components: { CustomDropdown },
  props: {
    row: {
      type: Object,
      required: true,
    },
    position: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    contactActions: null,
    contactActionSelection: null,
    addressActionSelection: null,
    selectedAddress: null,
  }),
  computed: {
    addressActions() {
      const actions = [{id: 'reject', text: 'Match - Reject Import'}];

      let hasExistingLocation = this.row.processingData?.entities?.location?.id;
      if (hasExistingLocation) {
        actions.push({id: 'update', text: 'Match - Override Existing'});
      }

      if (this.addressActionSelection === 'ignore') {
        actions.push({
          id: 'ignore',
          text: 'Match - Accept Exact Match'
        })
      }

      actions.push({
        id: 'insertNew',
        text: hasExistingLocation ? 'Not a Match - Accept Import' : 'No Match Found - Insert New'
      });

      return actions;
    },
    rowHeaders() {
      const headers = [
        'Source',
        'Matched Identifier',
      ];

      const fileData = this.row.rowData;

      if (this.getValueFromData(fileData, 'Contact Source')?.value) {
        headers.push('Contact Source');
      }

      if (this.$store.getters["user/account"]?.accountType === 'HCP'
        && this.getValueFromData(fileData, 'NPI')?.value
      ) {
        headers.push('NPI');
      }

      const firstName = this.getValueFromData(fileData, 'First Name')?.value ?? '';
      const lastName = this.getValueFromData(fileData, 'Last Name')?.value ?? '';
      if (firstName || lastName) {
        headers.push('Name');
      }

      if (this.$store.getters["user/account"]?.accountType === 'HCP'
        && this.getValueFromData(fileData, 'Specialty')?.value
      ) {
        headers.push('Specialty');
      }

      const addressLine1 = this.getValueFromData(fileData, 'Address Line 1')?.value ?? '';
      const addressLine2 = this.getValueFromData(fileData, 'Address Line 2')?.value ?? '';
      const city = this.getValueFromData(fileData, 'City')?.value ?? '';
      const state = this.getValueFromData(fileData, 'State Code')?.value ?? '';
      const postalCode = this.getValueFromData(fileData, 'Postal Code')?.value ?? '';
      const country = this.getValueFromData(fileData, 'Country')?.value ?? '';

      if (addressLine1 || addressLine2 || city || state || postalCode || country) {
        headers.push('Address');
      }

      const emailAddress = this.getValueFromData(fileData, 'Email Address')?.value ?? '';
      if (emailAddress) {
        headers.push('Email Address');
      }

      return headers;
    },
    rowsWithValues() {
      const databaseRow = {
        'Source': 'Database',
        'Matched Identifier': this.row?.processingData?.entities?.contact?.matchedIdentifier ?? 'N/A',
      };
      const fileRow = {
        'Source': 'File',
        'Matched Identifier': '',
      };

      const fileData = this.row?.rowData;
      const databaseData = this.row?.processingData?.entities;

      const headerMap = {
        'Contact Source': {databaseEntity: 'contactSource'},
        'NPI': {databaseEntity: 'contact'},
        'Name': {databaseEntity: 'contact', valueFunction: this.getDisplayName},
        'Specialty': {databaseEntity: 'contact'},
        'Address': {databaseEntity: 'location', valueFunction: this.getDisplayLocations},
        'Email Address': {databaseEntity: 'email'},
      };

      for (const header of this.rowHeaders) {
        const headerMapData = this.getValueFromData(headerMap, header);
        if (!headerMapData) {
          continue;
        }

        if (headerMapData.valueFunction) {
          fileRow[header] = headerMapData.valueFunction(fileData);

          if (headerMapData.databaseEntity === 'location') {
            databaseRow[header] = headerMapData.valueFunction(databaseData[headerMapData.databaseEntity], true);
          } else {
            databaseRow[header] = headerMapData.valueFunction(
              databaseData[headerMapData.databaseEntity]?.databaseRecord,
              true
            );
          }
        } else {
          fileRow[header] = this.getValueFromData(fileData, header)?.value ?? '';
          databaseRow[header] = this.getValueFromData(
            this.getValueFromData(databaseData, headerMapData.databaseEntity)?.databaseRecord,
            header
          )?.value ?? '';
        }
      }

      return [databaseRow, fileRow];
    },
    previewRow() {
      const preview = {
        'Source': 'Preview',
        'Matched Identifier': ''
      };

      const fileData = this.row?.rowData;

      if (this.contactActionSelection === 'reject') {
        preview['Contact Source'] = '';
        preview['NPI'] = '';
        preview['Name'] = '';
        preview['Specialty'] = '';
      } else {
        preview['Contact Source'] = this.getValueFromData(fileData, 'Contact Source')?.value ?? '';

        const dbData = this.row.processingData?.entities?.contact;

        if (this.$store.getters["user/account"]?.accountType === 'HCP') {
          const fileDataNPI = this.getValueFromData(fileData, 'NPI');
          preview['NPI'] = fileDataNPI?.value
            ? (fileDataNPI?.value ?? '')
            : (this.getValueFromData(dbData, 'NPI')?.value ?? '');

          const fileDataSpecialty = this.getValueFromData(fileData, 'Specialty');
          preview['Specialty'] = fileDataSpecialty?.value
            ? (fileDataSpecialty?.value ?? '')
            : (this.getValueFromData(dbData, 'Specialty')?.value ?? '');
        }

        // We need to make sure that if the file is only overwriting some data points instead of all, that
        // the preview row shows the result of the update correctly
        const fileFirstName = this.getValueFromData(fileData, 'First Name')?.value ?? '';
        const fileLastName = this.getValueFromData(fileData, 'Last Name')?.value ?? '';
        const dbFirstName = this.getValueFromData(dbData, 'First Name')?.value ?? '';
        const dbLastName = this.getValueFromData(dbData, 'Last Name')?.value ?? '';

        let firstName = '';
        let lastName = '';
        if (this.contactActionSelection === 'update') {
          firstName = fileFirstName ?? dbFirstName;
          lastName = fileLastName ?? dbLastName;
        } else {
          firstName = fileFirstName;
          lastName = fileLastName;
        }

        preview['Name'] = (!firstName && !lastName) ? 'N/A' : `${firstName} ${lastName}`;
      }

      if (this.addressActionSelection === 'reject') {
        preview['Address'] = '';
      } else {
        let dbAddressData = null;
        if (this.row.processingData?.entities?.location?.matches) {
          // If the processing data contains matches, make sure to show the selected address
          const selectedAddress = this.row.processingData?.entities?.location?.matches[this.selectedAddress] ?? {};

          dbAddressData = {
            'Address Line 1': {value: selectedAddress.addressLine1},
            'Address Line 2': {value: selectedAddress.addressLine2},
            'City': {value: selectedAddress.city},
            'State Code': {value: selectedAddress.state},
            'Postal Code': {value: selectedAddress.postalCode},
            'Country': {value: selectedAddress.country},
          };
        } else {
          // If no matches, there could still be a matched location in databaseRecord
          dbAddressData = this.row.processingData?.entities?.location?.databaseRecord ?? {};
        }

        // We need to make sure that if the file is only overwriting some data points instead of all, that
        // the preview row shows the result of the update correctly
        let addressLine1 = '';
        let addressLine2 = '';
        let city = '';
        let state = '';
        let postalCode = '';
        let country = '';
        if (this.addressActionSelection === 'update') {
          addressLine1 = this.getValueFromData(fileData, 'Address Line 1')?.value ?? this.getValueFromData(dbAddressData, 'Address Line 1')?.value ?? '';
          addressLine2 = this.getValueFromData(fileData, 'Address Line 2')?.value ?? dbAddressData['Address Line 2']?.value ?? '';
          city = fileData['City']?.value ?? dbAddressData['City']?.value ?? '';
          state = fileData['State Code']?.value ?? dbAddressData['State Code']?.value ?? '';
          postalCode = fileData['Postal Code']?.value ?? dbAddressData['Postal Code']?.value ?? '';
          country = fileData['Country']?.value ?? dbAddressData['Country']?.value ?? '';
        } else {
          addressLine1 = fileData['Address Line 1']?.value ?? '';
          addressLine2 = fileData['Address Line 2']?.value ?? '';
          city = fileData['City']?.value ?? '';
          state = fileData['State Code']?.value ?? '';
          postalCode = fileData['Postal Code']?.value ?? '';
          country = fileData['Country']?.value ?? '';
        }

        preview['Address'] = this.formatAddressMatchForDisplay({
          addressLine1,
          addressLine2,
          city,
          state,
          postalCode,
          country,
        });
      }

      if (fileData['Email Address']?.value) {
        preview['Email Address'] = fileData['Email Address']?.value ?? '';
      }

      return preview;
    },
    matchedContactId() {
      return this.row.processingData?.entities?.contact?.id;
    },
  },
  created() {
    this.contactActionSelection = this.row.processingData?.entities?.contact?.operation;
    this.addressActionSelection = this.row.processingData?.entities?.location?.operation;

    if (this.row.processingData?.entities?.location?.id) {
      this.selectedAddress = this.row.processingData?.entities?.location?.id;
    }
  },
  methods: {
    getContactActions(rowsWithValues) {
      const contactActions =  [
        {
          id: 'reject',
          text: 'Match - Reject Import',
        },
        {
          id: 'update',
          text: 'Match - Override Existing',
        },
      ]

      rowsWithValues.forEach((item) => {
        if (item['Matched Identifier'] === 'Contact ID') {
          contactActions.push({
            id: 'insertNew',
            text: 'Not a Match - Accept Import',
          })

          return contactActions;
        }
      });

      return contactActions;
    },
    /**
     * @param record
     * @returns {string}
     */
    getDisplayName(record) {
      if (!record) {
        return 'N/A';
      }

      const firstName = this.getValueFromData(record, 'First Name')?.value ?? '';
      const lastName = this.getValueFromData(record, 'Last Name')?.value ?? '';

      if (!firstName && !lastName) {
        return 'N/A';
      } else {
        return `${firstName} ${lastName}`;
      }
    },
    getDisplayLocations(record, isDatabaseRecord) {
      if (isDatabaseRecord && !record?.id && !record?.matches) {
        return [{}];
      }

      if (!record.matches) {
        const locationToDisplay = record?.databaseRecord ?? record;
        return [
          {
            addressLine1: this.getValueFromData(locationToDisplay, 'Address Line 1')?.value ?? '',
            addressLine2: this.getValueFromData(locationToDisplay, 'Address Line 2')?.value ?? '',
            city: this.getValueFromData(locationToDisplay, 'City')?.value ?? '',
            state: this.getValueFromData(locationToDisplay, 'State Code')?.value ?? '',
            postalCode: this.getValueFromData(locationToDisplay, 'Postal Code')?.value ?? '',
            country: this.getValueFromData(locationToDisplay, 'Country')?.value ?? '',
          }
        ]
      }

      const matches = [];

      for (const addressId in record.matches) {
        matches.push({
          id: addressId,
          ...record.matches[addressId]
        })
      }

      return matches;
    },
    process() {
      const request = {
        id: this.row.id,
        position: this.position,
        processingData: {...this.row.processingData}
      };

      if (request.processingData.entities) request.processingData.entities.contact.operation = this.contactActionSelection;
      if (this.addressActionSelection) {
        request.processingData.entities.location.id = this.selectedAddress;
        request.processingData.entities.location.operation = this.addressActionSelection;
      }

      // if processed => emit to parent so parent can do a data-refresh
      this.$emit('confirmedChoices', request);
    },
    /**
     * Formats dates like one of the following based on available data:
     *
     * Full Address: 123 Main St, Suite 100, New York, NY 10001 US
     * Partial: New York, NY 10001 US
     * Just postal code: 10001 US
     *
     * @param match
     * @returns {string}
     */
    formatAddressMatchForDisplay(match) {
      const addressLine1 = match.addressLine1;
      const addressLine2 = match.addressLine2;
      const city = match.city;
      const stateCode = match.state;
      const postalCode = match.postalCode;
      const country = match.country;

      let address = '';
      if (addressLine1) {
        address += addressLine1;

        if (addressLine2) {
          address += ', ' + addressLine2;
        }
      }
      if (address) {
        address += ', ';
      }
      if (city) {
        address += city;
      }
      if (stateCode) {
        address += ', ' + stateCode;
      }
      if (address) {
        address += ' ';
      }
      if (postalCode) {
        address += postalCode;
      }
      if (address) {
        address += ' ';
      }
      if (country) {
        address += country;
      }

      return address;
    },
    redirectToContactProfile() {
      let route = this.$router.resolve({name: 'ContactDetails', params: {id: this.matchedContactId}});

      window.open(route.href, '_blank');
    },
    undo() {
      this.$emit('undo', {
        id: this.row.id,
        position: this.position,
      });
    },
    getValueFromData(data, valueKey) {
      if (!data || !(valueKey in data)) {
        return null;
      }

      return data[valueKey];
    },
  },
}
</script>

<style lang="scss" scoped>
.row-item {
  padding-top: 15px;

  h6 {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #66788e;
    padding-bottom: 16px;
  }

  &.completed {
    h6 {
      color: #1f9f43;

      i {
        color: #1f9f43;
      }

      .undo-link {
        text-decoration: underline;
        color: #1976d2;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .review-row {
      display: none;
    }
  }

  .row-table {
    margin-bottom: 17px;

    // .v-data-table {
    &__header {
      th {
        font-size: 13px !important;
        color: #241c15 !important;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        border-top: 1px solid rgba(0, 0, 0, 0.09);
      }

      th:first-child {
        border-top-left-radius: 5px;
        border-left: 1px solid rgba(0, 0, 0, 0.09);
      }

      th:last-child {
        border-top-right-radius: 5px;
        border-right: 1px solid rgba(0, 0, 0, 0.09);
      }
    }
    &__content {
      tr td {
        font-size: 13px !important;
        color: #241c15 !important;
        font-family: "Open Sans", sans-serif !important;
        * {
          font-size: 13px;
          color: #241c15;
          font-family: "Open Sans", sans-serif;
        }
        a {
          text-decoration: underline;
          color: #1976d2;
        }
      }

      tr {
        td:first-child {
          border-left: 1px solid rgba(0, 0, 0, 0.09);
        }

        td:last-child {
          border-right: 1px solid rgba(0, 0, 0, 0.09);
        }
      }

      tr.preview-row td {
        background-color: rgba(216, 216, 216, 0.09);
      }
    }

    thead {
      tr > th {
        border-bottom: solid 1px #dee7ee !important;
      }
    }
    tbody {
      // table border colors
      tr > td {
        border-bottom: solid 1px #dee7ee;
      }
      tr:hover {
        background: inherit !important;
      }

      .position-relative {
        position: relative;
      }

      span.match-number {
        //position: absolute;
        top: 5px;
        left: -40px;
        font-size: 9px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: -0.05px;
        text-align: center;
        color: #2b84eb;
      }

      .multiple-items {
        padding: 10px 0;
      }

      .radio-group-item {
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        ::v-deep .v-input__slot {
          margin-bottom: 0 !important;
        }
      }
    }
    // }

    .panel-button {
      font-family: "Open Sans", sans-serif;
      font-size: 13px !important;
      color: #66788e;
      text-transform: none;
      background: #ecf1f5 !important;
    }
    .panel-button[aria-expanded=true] {
      color: white;
      background: #2b84eb !important;
      opacity: 1 !important;
    }
  }
}
</style>
