<template>
  <v-container ref="container" class="mt-3 px-sm-10 px-0 elevation-0" fluid>
    <PageHeader
      header-text="Contact Import Review"
      class="mb-[30px]"
      regular-button-text="Process Import"
      :regular-button-visible="importInfo && importInfo.totalRows === rowCountReadyToProcess"
      @regular-button-click="processImport"
    >
      <template #subheader>
        <v-row>
          <v-col cols="6">
            Review duplicates and errors found in your contact import
          </v-col>
        </v-row>
      </template>
    </PageHeader>
    <div class="clearfix" />
    <v-row class="mb-3">
      <v-col>
        <div
          v-if="importInfo"
          class="statuses w-100"
        >
          <div
            class="ok-status"
          >
            <strong>{{ rowCountReadyToProcess }}</strong> rows ready to process
          </div>
          <div class="pending-status">
            <strong>{{ importInfo.amountOfRowsRequiringReview - processedCount }}</strong> rows pending review
          </div>
          <div>
            <strong>{{ importInfo.totalRows }}</strong> total rows
          </div>
        </div>
      </v-col>
      <v-col
        v-if="countTotal > pageSizes[0]"
        cols="auto"
        class="d-flex flex-nowrap align-center justify-end flex-grow-1 pl-2"
      >
        <pagination-buttons
          :current-page-number="currentPageNumber"
          :current-page-size="currentPageSize"
          :count-total="countTotal"
          :page-sizes="pageSizes"
          @next-page="nextPage"
          @prev-page="prevPage"
          @change-page-size="(item) => $emit('change-page-size', item)"
        />
      </v-col>
    </v-row>
    <row-item
      v-for="(rowInfo, index) in importRows"
      :key="index"
      :position="index+1"
      :row="rowInfo"
      @confirmedChoices="processRow"
      @undo="undoRow"
    />
    <v-row
      v-if="isShowBottomImportBtn && importInfo && importInfo.totalRows === rowCountReadyToProcess"
    >
      <v-col>
        <v-btn
          class="my-1 ml-sm-2 page-header-btn float-right"
          width="185px"
          height="46px"
          tile
          color="primary"
          :block="$vuetify.breakpoint.xsOnly"
          @click="processImport"
        >
          Process Import
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import RowItem from "@/views/Imports/components/RowItem";
import PaginationButtons from "@/sharedComponents/pagination/PaginationButtons";

export default {
  name: 'ContactImportProcessView',
  components: { RowItem, PageHeader, PaginationButtons },
  data: () => ({
    importId: null,
    importInfo: null,
    importRows: null,
    processedCount: 0,
    currentPageNumber: 1,
    currentPageSize: 25,
    countTotal: 0,
    pageSizes: [25, 50, 100],
    componentHeight: 0,
  }),
  computed: {
    rowCountReadyToProcess() {
      if (!this.importInfo) {
        return 0;
      }

      return this.importInfo.totalRows - this.importInfo.amountOfRowsRequiringReview + this.processedCount;
    },
    isShowBottomImportBtn() {
      return this.componentHeight > 2 * window.innerHeight;
    },
  },
  watch: {
    currentPageNumber: {
      handler() {
        this.fetchImportRows();
        this.updateComponentHeight();
      }
    },
    processedCount: {
      handler() {
        this.updateComponentHeight();
      }
    },
  },
  async created() {
    this.importId = this.$route.params.id;

    await this.fetchImport();
    await this.fetchImportRows();
  },
  mounted() {
    this.updateComponentHeight();
  },
  methods: {
    updateComponentHeight() {
      this.componentHeight = this.$refs.container.clientHeight;
    },
    async fetchImport() {
      const response = await this.$rest.imports.get_resource(this.importId);
      if (!response.data) {
        return;
      }

      this.importInfo = { ...response.data };
    },
    async fetchImportRows(params = {}) {
      const response = await this.$rest.imports.get_import_rows(this.importId, {
        requiresReview: true,
        forImportReview: true,
        page: this.currentPageNumber,
        ...params,
      });
      if (!response.data) {
        return;
      }

      this.countTotal = response.data.totalCount;
      this.importRows = [ ...response.data.items ];
    },
    async nextPage() {
      if (this.processedCount > 0) {
        await this.fetchImportRows();
        this.processedCount = 0;
        return
      }

      this.currentPageNumber++;
    },
    prevPage() {
      this.currentPageNumber--;
    },
    async processRow(row) {
      const response = await this.$rest.imports.put_import_row(row.id, {
        requiresReview: false,
        processingData: row.processingData,
      });

      this.$set(this.importRows, row.position - 1, response.data);
      this.processedCount++;
    },
    async undoRow(row) {
      const response = await this.$rest.imports.put_import_row(row.id, {
        requiresReview: true,
      })

      this.$set(this.importRows, row.position -1, response.data);
      this.processedCount--;
    },
    processImport() {
      this.$rest.imports.put_resource(this.importInfo.id, {
        "status": "analyzing",
        // Just in case, set the count to 0
        "amountOfRowsRequiringReview": 0,
        // This field specifically causes it to be managed by the backend again
        "processImport": true
      });

      if (this.importInfo.importData?.originalImportType === 'action') {
        this.$router.push({name: 'ImportActions'});
      } else if (this.importInfo.importType === 'contact') {
        this.$router.push({
          name: 'AddContact',
          params: {
            type: 'BulkImportContact'
          }
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.mb-\[30px\] {
  margin-bottom: 30px;
}

.statuses {
  div {
    float: left;
    padding-right: 10px;
    padding-left: 10px;
    border-right: 1px solid #e9edf0;
    font-size: 13px;
    color: #241c15;
  }

  div:last-child {
    border-right: 0;
  }

  div:first-child {
    padding-left: 0;
  }

  .ok-status strong {
    color: #1f9f43;
  }

  .pending-status strong {
    color: #9e6b00;
  }

  .error-status {
    color: #dc2929;
    text-decoration: underline;
  }
}

.error-btn {
  padding: 8px 24px;
  border-radius: 4px;
  border: solid 1px #dc2929;
  color: #dc2929;
  font-size: 13px;
  font-weight: 600;
}

.error-btn:hover {
  background-color: #dc2929;
  color: #FFFFFF;
}

.clearfix {
  clear: both;
  margin-bottom: 20px;
}

::v-deep .page-header-btn .v-btn__content {
  font-size: 13px !important;
}
</style>
